import { connector } from 'redux/utils/connector';

import YourAddressPicker from 'components/modules/YourDetails/Forms/YourAddressPicker';
import {
  addressPickerBillingActions,
  addressPickerPreviousActions,
} from '../actions';
import {
  STORE_NAME,
  ADDRESS_PICKER_KEY_BILLING,
  ADDRESS_PICKER_KEY_PREVIOUS,
} from '../constants';

export const YourDetailsYourAddressBillingPicker = connector(
  STORE_NAME,
  (state) => ({
    addressPicker: state.getIn([STORE_NAME, ADDRESS_PICKER_KEY_BILLING]).toJS(),
  }),
  addressPickerBillingActions
)(YourAddressPicker);

export const YourDetailsYourAddressPreviousPicker = connector(
  STORE_NAME,
  (state) => ({
    addressPicker: state
      .getIn([STORE_NAME, ADDRESS_PICKER_KEY_PREVIOUS])
      .toJS(),
  }),
  addressPickerPreviousActions
)(YourAddressPicker);
