import { houseAccounts, partnerOrCustomerNumberRegEx } from '../constants';
import { referralAndLegalSection } from '../form';

const {
  TERMS_AND_CONDITIONS,
  IS_REFERRAL,
  REFERRER_ACCOUNT,
  REFERRER_NAME_FIRST,
  REFERRER_NAME_LAST,
} = referralAndLegalSection;

const errorMessages = {
  required: 'This field is required',
  invalidPartner: 'Please enter a valid partner or account number',
};

const validatePartnerName = (value) => {
  if (!value || value.length === 0) {
    return {
      valid: false,
      reason: errorMessages.required,
    };
  }

  return {
    valid: true,
  };
};

const validateAccountNumber = (value) => {
  if (!value || value.length === 0) {
    return {
      valid: false,
      reason: errorMessages.required,
    };
  }

  const normalizedNumber = value.trim().toUpperCase();

  const isValid =
    houseAccounts.includes(normalizedNumber) ||
    normalizedNumber.match(partnerOrCustomerNumberRegEx);

  if (!isValid) {
    return {
      valid: false,
      reason: errorMessages.invalidPartner,
    };
  }

  return {
    valid: true,
  };
};

const validateTermsAndConditions = (value) => {
  const isValid = value;

  if (!isValid) {
    return {
      valid: false,
      reason: errorMessages.required,
    };
  }

  return {
    valid: true,
  };
};

const validateReferralAndLegal = (fields) => {
  let results = [
    {
      field: TERMS_AND_CONDITIONS,
      ...validateTermsAndConditions(fields[TERMS_AND_CONDITIONS]),
    },
  ];

  if (fields[IS_REFERRAL]) {
    results = [
      ...results,
      {
        field: REFERRER_NAME_FIRST,
        ...validatePartnerName(fields[REFERRER_NAME_FIRST]),
      },
      {
        field: REFERRER_NAME_LAST,
        ...validatePartnerName(fields[REFERRER_NAME_LAST]),
      },
      {
        field: REFERRER_ACCOUNT,
        ...validateAccountNumber(fields[REFERRER_ACCOUNT]),
      },
    ];

    const nameFirstResult = results.filter(
      (result) => result.field === REFERRER_NAME_FIRST
    );

    const nameLastResult = results.filter(
      (result) => result.field === REFERRER_NAME_LAST
    );

    const accountResult = results.filter(
      (result) => result.field === REFERRER_ACCOUNT
    );

    const validNameOnly =
      fields[REFERRER_ACCOUNT] == '' &&
      nameFirstResult[0].valid &&
      nameLastResult[0].valid;

    const validAccountOnly =
      fields[REFERRER_NAME_FIRST] == '' &&
      fields[REFERRER_NAME_LAST] == '' &&
      accountResult[0].valid;

    if (validAccountOnly) {
      results = results.filter((result) => {
        return (
          result.field !== REFERRER_NAME_FIRST &&
          result.field !== REFERRER_NAME_LAST
        );
      });
    }

    if (validNameOnly) {
      results = results.filter((result) => {
        return result.field !== REFERRER_ACCOUNT;
      });
    }
  }

  const isValid = results.every((result) => {
    return result.valid;
  });

  return {
    valid: isValid,
    fields: results,
  };
};

export {
  errorMessages,
  validateReferralAndLegal,
  validatePartnerName,
  validateAccountNumber,
  validateTermsAndConditions,
};
