import { call, fork, put, select } from 'redux-saga/effects';

import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import CashbackApi from 'redux/modules/Cashback/api';
import { putCashback } from 'redux/modules/Cashback/services';
import { EntryFormApi } from 'redux/modules/EntryForm/api';
import { OrderSummaryApi } from 'app/redux/modules/OrderSummary/api';
import { translateBackendErrorToMessage } from 'redux/modules/Shared/BackendParser';

import { openErrorGlobalDialog } from 'redux/modules/GlobalDialog/actions';
import { notificationKeys } from 'redux/modules/Notifications/library';
import { addNotification } from 'redux/modules/Notifications/actions';

import {
  setFormAboutYouValidation,
  setFormPristine,
  sectionSubmissionFailed,
  setFormAboutYouLoading,
  setPaperBillingModal,
  submitDetailsForm,
  setCreditCheck,
  requestEeccContractSuccess,
  requestEeccContractFailure,
} from '../actions';
import {
  OCCUPANCY,
  PAPER_BILLING_DEFAULT,
  PAPER_BILLING_EO,
  ABOUT_YOU_SECTION,
} from '../constants';
import { aboutYouSection } from '../form';
import {
  postAccountHolder,
  putCustomerPassword,
  putPropertyDetails,
  getCreditCheck,
  getContractGenerate,
  postContractEmail,
} from '../services';
import { validationAboutYou } from '../validation/aboutYou';
import { getAboutYouFormFields, getPreviousAddressState } from '../api';

const {
  ABOUT_YOU_TITLE,
  ABOUT_YOU_NAME_FIRST,
  ABOUT_YOU_NAME_LAST,
  ABOUT_YOU_DATE_OF_BIRTH,
  ABOUT_YOU_CONTACT_NUMBER,
  ABOUT_YOU_EMAIL,
  ABOUT_YOU_PASSWORD,

  ABOUT_YOU_ADDITIONAL_HOLDER_SELECT,
  ABOUT_YOU_ADDITIONAL_TITLE,
  ABOUT_YOU_ADDITIONAL_NAME_FIRST,
  ABOUT_YOU_ADDITIONAL_NAME_LAST,
  ABOUT_YOU_ADDITIONAL_DATE_OF_BIRTH,

  ABOUT_YOU_CBC_ADDITIONAL_SELECT,
  ABOUT_YOU_CBC_ADDITIONAL_NAME_FIRST,
  ABOUT_YOU_CBC_ADDITIONAL_NAME_LAST,
  ABOUT_YOU_CBC_ADDITIONAL_DATE_OF_BIRTH,

  ABOUT_YOU_RECENT_MOVE,
  ABOUT_YOU_OWNER,
  ABOUT_YOU_PAPER_BILLING_CONFIRM,
} = aboutYouSection;

const ACCOUNT_KEYS = {
  salutation: 'salutation',
  first: 'first',
  last: 'last',
  dob: 'dob',
  contactNumber: 'contactNumber',
  email: 'email',
  trustedSecondaryEmail: 'trustedSecondaryEmail',
  isMainAccountHolder: 'isMainAccountHolder',
};

const mapPrimaryHolder = (fields) => ({
  [ACCOUNT_KEYS.salutation]: fields[ABOUT_YOU_TITLE],
  [ACCOUNT_KEYS.first]: fields[ABOUT_YOU_NAME_FIRST],
  [ACCOUNT_KEYS.last]: fields[ABOUT_YOU_NAME_LAST],
  [ACCOUNT_KEYS.dob]: fields[ABOUT_YOU_DATE_OF_BIRTH],
  [ACCOUNT_KEYS.contactNumber]: fields[ABOUT_YOU_CONTACT_NUMBER],
  [ACCOUNT_KEYS.email]: fields[ABOUT_YOU_EMAIL] || undefined,
  [ACCOUNT_KEYS.trustedSecondaryEmail]: false,
  [ACCOUNT_KEYS.isMainAccountHolder]: true,
});

const mapAdditionalHolder = (fields) => ({
  [ACCOUNT_KEYS.salutation]: fields[ABOUT_YOU_ADDITIONAL_TITLE],
  [ACCOUNT_KEYS.first]: fields[ABOUT_YOU_ADDITIONAL_NAME_FIRST],
  [ACCOUNT_KEYS.last]: fields[ABOUT_YOU_ADDITIONAL_NAME_LAST],
  [ACCOUNT_KEYS.dob]: fields[ABOUT_YOU_ADDITIONAL_DATE_OF_BIRTH],
  [ACCOUNT_KEYS.contactNumber]: undefined,
  [ACCOUNT_KEYS.email]: undefined,
  [ACCOUNT_KEYS.trustedSecondaryEmail]: false,
  [ACCOUNT_KEYS.isMainAccountHolder]: false,
});

const getPropertyValues = (state) => {
  const fields = getAboutYouFormFields(state);

  const isTenant = EntryFormApi.isTenant(state);
  const occupancy = {
    type: isTenant ? OCCUPANCY.TENANCY : OCCUPANCY.OWNERSHIP,
  };

  const recentMove = fields[ABOUT_YOU_RECENT_MOVE];
  const newOwner = fields[ABOUT_YOU_OWNER];

  if (isTenant) {
    occupancy.tenancy = {};
  } else {
    occupancy.ownership = {
      recent: recentMove,
    };
  }

  let previousOccupancy = null;

  if (recentMove) {
    previousOccupancy = {
      type: newOwner ? OCCUPANCY.TENANCY : OCCUPANCY.OWNERSHIP,
    };

    if (newOwner) {
      previousOccupancy.tenancy = {};
    } else {
      previousOccupancy.ownership = { recent: false };
    }
  }

  return {
    occupancy,
    previousOccupancy,
    previousAddress: getPreviousAddressState(state),
  };
};

function* formValidate() {
  const fields = yield select(getAboutYouFormFields);
  const previousAddress = yield select(getPreviousAddressState);
  const isTenant = yield select(EntryFormApi.isTenant);

  const props = {
    isTenant,
    previousAddress,
  };
  const validation = validationAboutYou(fields, props);
  yield put(setFormAboutYouValidation(validation.valid, validation.fields));
}

function* postPrimaryAccountHolder(fields) {
  const primaryAccountHolder = mapPrimaryHolder(fields);
  try {
    yield call(postAccountHolder, primaryAccountHolder);
  } catch (error) {
    const message = translateBackendErrorToMessage(error);
    Sentry.log(error, ERRORS.UPDATE_ABOUT_YOU_NAME_EMAIL);
    return yield put(sectionSubmissionFailed(message));
  }
}

function* postAdditionalAccountHolder(fields) {
  let additionalAccountHolder;

  if (fields[ABOUT_YOU_ADDITIONAL_HOLDER_SELECT]) {
    additionalAccountHolder = mapAdditionalHolder(fields);
  }

  if (!additionalAccountHolder) {
    return;
  }

  try {
    yield call(postAccountHolder, additionalAccountHolder, false);
  } catch (error) {
    const message = translateBackendErrorToMessage(error);
    Sentry.log(error, ERRORS.UPDATE_ABOUT_YOU_NAME_EMAIL);
    return yield put(sectionSubmissionFailed(message));
  }
}

function* submitAddress() {
  const propertyQuestionValues = yield select(getPropertyValues);
  try {
    yield call(putPropertyDetails, propertyQuestionValues);
  } catch (error) {
    const message = translateBackendErrorToMessage(error);
    Sentry.log(error, ERRORS.UPDATE_YOUR_DETAILS_ADDRESS);
    return yield put(sectionSubmissionFailed(message));
  }
}

function* submitPassword(fields) {
  const password = fields[ABOUT_YOU_PASSWORD];

  try {
    yield call(putCustomerPassword, password);
  } catch (error) {
    const message = translateBackendErrorToMessage(error);
    Sentry.log(error, ERRORS.UPDATE_ABOUT_YOU_CLUBHOUSE);
    return yield put(sectionSubmissionFailed(message));
  }
}

function* addCashbackAccount(fields) {
  const hasCashback = yield select(CashbackApi.getHasApplied);
  if (!hasCashback) {
    return;
  }

  const accountHolders = [mapPrimaryHolder(fields)];

  if (fields[ABOUT_YOU_CBC_ADDITIONAL_SELECT]) {
    accountHolders.push({
      first: fields[ABOUT_YOU_CBC_ADDITIONAL_NAME_FIRST],
      last: fields[ABOUT_YOU_CBC_ADDITIONAL_NAME_LAST],
      dob: fields[ABOUT_YOU_CBC_ADDITIONAL_DATE_OF_BIRTH],
    });
  }

  const composed = {
    holders: accountHolders.map(
      ({ first: firstName, last: lastName, dob }) => ({
        firstName,
        lastName,
        dob,
      })
    ),
  };

  try {
    yield call(putCashback, composed);
  } catch (error) {
    Sentry.log(error, ERRORS.YOUR_DETAILS_SUBMIT_CBC);
  }
}

function* checkPaperBilling() {
  const fields = yield select(getAboutYouFormFields);
  const isEnergyOnly = yield select(OrderSummaryApi.getIsEnergyOnly);

  const isPaperBillingConfirmed = fields[ABOUT_YOU_PAPER_BILLING_CONFIRM];
  const isEmailEntered = fields[ABOUT_YOU_EMAIL];

  if (!isEmailEntered && !isPaperBillingConfirmed) {
    const variant = isEnergyOnly ? PAPER_BILLING_EO : PAPER_BILLING_DEFAULT;
    yield put(setFormAboutYouLoading(false));
    return yield put(setPaperBillingModal(true, variant));
  } else {
    return yield put(submitDetailsForm());
  }
}

function* formSubmit() {
  yield put(setFormPristine(ABOUT_YOU_SECTION, false));
  const fields = yield select(getAboutYouFormFields);

  yield call(postPrimaryAccountHolder, fields);
  yield call(postAdditionalAccountHolder, fields);
  yield call(submitAddress);
  yield call(submitPassword, fields);

  // handle cashback card now that we have account holder details
  yield fork(addCashbackAccount, fields);
}

function* getShouldCreditCheck() {
  try {
    const response = yield call(getCreditCheck);
    yield put(
      setCreditCheck(response.data.shouldCreditCheck.shouldCreditCheck)
    );
  } catch (error) {
    Sentry.log(error, ERRORS.GET_ABOUT_YOU_INITIAL_REQUEST);
  }
}

function* generateContract() {
  try {
    yield call(getContractGenerate);
  } catch (error) {
    Sentry.log(error, ERRORS.GET_ABOUT_YOU_INITIAL_REQUEST);
    throw new Error(error);
  }
}

function* postContract() {
  const fields = yield select(getAboutYouFormFields);
  const email = fields[aboutYouSection.ABOUT_YOU_EMAIL];

  try {
    yield call(postContractEmail, email);
    yield put(requestEeccContractSuccess());
    yield put(
      addNotification({
        message: notificationKeys.DETAILS_EECC_EMAIL_SUBMIT,
        variant: 'success',
      })
    );
  } catch (error) {
    const message = error?.response?.data?.message || error?.message;
    yield put(requestEeccContractFailure(message));
    yield put(openErrorGlobalDialog(message));
    Sentry.log(error, ERRORS.GET_ABOUT_YOU_INITIAL_REQUEST);
  }
}

export {
  mapPrimaryHolder,
  mapAdditionalHolder,
  formValidate,
  postPrimaryAccountHolder,
  postAdditionalAccountHolder,
  submitAddress,
  submitPassword,
  addCashbackAccount,
  formSubmit,
  getPropertyValues,
  checkPaperBilling,
  getShouldCreditCheck,
  generateContract,
  ACCOUNT_KEYS,
  postContract,
};
