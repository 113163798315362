import { compact, isEmpty } from 'lodash';

import { sumPrices } from 'app/redux/utils/priceUtils';
import { PRODUCT_INSURANCE } from 'app/redux/modules/Bundles/constants';

const getItems = (incomeProtector, boiler, homeInsurance) => {
  const features = [];

  if (homeInsurance) {
    features.push('Home Insurance');
  }

  if (incomeProtector) {
    features.push(incomeProtector.description);
  }

  if (boiler) {
    // TODO: Confirm whether this should be fixed value
    // or depend on API response
    features.push('Boiler Cover');
  }

  return isEmpty(features) ? null : features;
};

const transformInsurance = ({ boiler, incomeProtector, homeInsurance }) => {
  if (!boiler && !incomeProtector && !homeInsurance) {
    return null;
  }

  const boilerPrice = boiler?.quote?.price;
  const protectorPrice = incomeProtector?.price;
  const homeInsurancePrice = homeInsurance?.price;

  const items = getItems(incomeProtector, boiler, homeInsurance);
  const prices = compact([boilerPrice, protectorPrice, homeInsurancePrice]);

  return {
    discountedPrice: null,
    id: PRODUCT_INSURANCE,
    items,
    oneTimeCost: null,
    price: sumPrices(prices),
  };
};

export { transformInsurance };
