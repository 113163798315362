import CashbackApi from 'redux/modules/Cashback/api';
import { OrderSummaryApi } from 'app/redux/modules/OrderSummary/api';
import { ADD_ON_CASHBACK_CARD } from 'app/redux/modules/OrderSummary/constants';

const transformCashback = (state) => {
  if (!CashbackApi.getHasApplied(state)) {
    return null;
  }

  const breakdown = OrderSummaryApi.getBreakdownProduct(state, 'cashback');
  const price = CashbackApi.getMonthlyFee(state);

  return {
    discountedPrice: null,
    id: ADD_ON_CASHBACK_CARD,
    items: null,
    oneTimeCost: breakdown?.totalInitialCost || null,
    price,
  };
};

export { transformCashback };
