import { REQUEST_FETCHING } from 'app/constants/requestStatus';
import { OrderSummaryApi } from 'app/redux/modules/OrderSummary/api';
import { YOUR_DETAILS_SUBMIT_REQ } from 'app/redux/modules/OrderSummary/constants';

import {
  STORE_NAME,
  ADDRESS_PICKER_KEY_PREVIOUS,
  FORM_SECTIONS,
  FORM_ERRORS,
  DIRECT_DEBIT_SECTION,
  ABOUT_YOU_SECTION,
  ADDRESS_PICKER_KEY_BILLING,
  REFERRAL_AND_LEGAL_SECTION,
  ONE_TOUCH_SWITCH_SECTION,
} from './constants';
import {
  aboutYouSection,
  referralAndLegalSection,
  directDebitSection,
  oneTouchSwitchSection,
  FIND_STATUS,
  HAS_EXISTING_LINE,
  PROVIDER_NOT_LISTED,
} from './form';

const partnerReferrerRegEx = /^[A-Z]{1,2}[0-9]{4,5}$/;
const { IS_REFERRAL, REFERRER_ACCOUNT } = referralAndLegalSection;
const { BILLING_ADDRESS_SAME } = directDebitSection;

export const getFormFields = (state, form) => {
  return state.getIn([STORE_NAME, 'forms', form, 'values']).toJS();
};

export const getFormField = (state, form, field) => {
  return state.getIn([STORE_NAME, 'forms', form, 'values', field]);
};

export const getFormValid = (state, form) => {
  const isValid = state.getIn([STORE_NAME, 'forms', form, 'valid']);
  return isValid;
};

export const getFormError = (state, form) => {
  return state.getIn([STORE_NAME, 'forms', form, 'error']);
};

export const getFormValidationErrors = (state, form) => {
  return state
    .getIn([STORE_NAME, 'forms', form, 'fieldErrors'])
    .toJS()
    .reduce(
      (acc, error) => ({
        ...acc,
        [error.field]: {
          ...error,
        },
      }),
      {}
    );
};

export const getFieldPristine = (state, form) => {
  return state.getIn([STORE_NAME, 'forms', form, 'fieldPristine']).toJS();
};

export const getAnyFieldPristine = (state, form) => {
  const fieldPristine = getFieldPristine(state, form);
  return Object.values(fieldPristine).some((f) => Boolean(f));
};

export const getAddressState = (state, addressKey) => {
  const pickerState = state.getIn([STORE_NAME, addressKey]);
  const manualEntry = state.getIn([STORE_NAME, addressKey, 'manualEntry']);

  if (manualEntry) {
    const addressFields = pickerState.get('addressFields').toJS();

    return Object.keys(addressFields).length > 0
      ? {
          ...addressFields,
          postcode: pickerState.get('postcode'),
        }
      : null;
  }

  const selectedAddress = pickerState.get('selectedAddress');
  const addressList = pickerState.get('addressList');
  const addressResult = addressList
    ?.find((a) => a.get('uuid') === selectedAddress)
    ?.toJS();

  return addressResult;
};

export const getFirstHolderName = (state) => {
  const fieldValues = getFormFields(state, ABOUT_YOU_SECTION);

  return {
    firstName: fieldValues[aboutYouSection.ABOUT_YOU_NAME_FIRST],
    surname: fieldValues[aboutYouSection.ABOUT_YOU_NAME_LAST],
  };
};

export const getFormsValid = (state) => {
  return FORM_SECTIONS.map((key) => getFormValid(state, key)).every(Boolean);
};
export const getFormsSubmitting = (state) => {
  const {
    [YOUR_DETAILS_SUBMIT_REQ]: yourDetailsSubmitRequestStatus,
  } = OrderSummaryApi.getRequestStatus(state);

  return yourDetailsSubmitRequestStatus === REQUEST_FETCHING;
};

export const getFormErrors = (state) => {
  const errors = state.getIn([STORE_NAME, FORM_ERRORS]);
  return errors && errors.toJS();
};

export const getWholeFormValidationErrors = (state) =>
  FORM_SECTIONS.reduce(
    (formErrors, formKey) => ({
      ...formErrors,
      ...getFormValidationErrors(state, formKey),
    }),
    {}
  );

export const getScrollToError = (state) =>
  state.getIn([STORE_NAME, 'scrollToError']);

export const getAboutYouFormFields = (state) =>
  getFormFields(state, ABOUT_YOU_SECTION);

export const getPreviousAddressState = (state) =>
  getAddressState(state, ADDRESS_PICKER_KEY_PREVIOUS);

export const getBillingAddressState = (state) =>
  getAddressState(state, ADDRESS_PICKER_KEY_BILLING);

export const getBillingAddressSame = (state) =>
  state.getIn([
    STORE_NAME,
    'forms',
    DIRECT_DEBIT_SECTION,
    'values',
    BILLING_ADDRESS_SAME,
  ]);

export const getDirectDebitFormFields = (state) =>
  getFormFields(state, DIRECT_DEBIT_SECTION);

export const getDirectDebitFormError = (state) =>
  getFormError(state, DIRECT_DEBIT_SECTION);

export const getDirectDebitFieldsErrors = (state) =>
  state
    .getIn([STORE_NAME, 'forms', DIRECT_DEBIT_SECTION, 'fieldErrors'])
    .toJS();

export const getReferralAndLegalFormFields = (state) =>
  getFormFields(state, REFERRAL_AND_LEGAL_SECTION);

export const getReferralAndLegalFieldsErrors = (state) =>
  state
    .getIn([STORE_NAME, 'forms', REFERRAL_AND_LEGAL_SECTION, 'fieldErrors'])
    .toJS();

export const getPaperBillingModal = (state) =>
  state
    .getIn([STORE_NAME, 'forms', ABOUT_YOU_SECTION, 'paperBillingModal'])
    .toJS();

export const getShouldCreditCheck = (state) =>
  state.getIn([STORE_NAME, 'shouldCreditCheck']);

export const getReferrerType = (state) => {
  const formFields = getReferralAndLegalFormFields(state);

  if (!formFields[IS_REFERRAL]) {
    return null;
  }
  const isPartnerAccount = formFields[REFERRER_ACCOUNT].match(
    partnerReferrerRegEx
  );

  return isPartnerAccount ? 'Partner' : 'Customer';
};

export const getEeccRequestLoading = (state) =>
  state.getIn([
    STORE_NAME,
    'forms',
    ABOUT_YOU_SECTION,
    'salesEeccContractLoading',
  ]);

export const getContractGenerationStatus = (state) =>
  state.getIn([STORE_NAME, 'contractGenerationStatus']);

export const getOneTouchSwitchFormFields = (state) =>
  getFormFields(state, ONE_TOUCH_SWITCH_SECTION);

export const getOTSTermsAndConditionsState = (state, broadband) => {
  if (!broadband) {
    return false;
  }
  const fieldValues = getFormFields(state, ONE_TOUCH_SWITCH_SECTION);

  const hasExistingLine = fieldValues[oneTouchSwitchSection.HAS_EXISTING_LINE];
  const providerNotListed =
    fieldValues[oneTouchSwitchSection.PROVIDER_NOT_LISTED];
  const findStatusFinished =
    fieldValues[oneTouchSwitchSection.FIND_STATUS] === 'SUCCESS' ||
    fieldValues[oneTouchSwitchSection.FIND_STATUS] === 'FAIL';

  return hasExistingLine || providerNotListed || findStatusFinished;
};

export const getSurnameValid = (state) => {
  return getFormValidationErrors(state, ABOUT_YOU_SECTION).AboutYouNameLast
    .valid;
};

export const getOneTouchSwitchFindStatus = (state) => {
  const existingLine = getFormField(
    state,
    ONE_TOUCH_SWITCH_SECTION,
    HAS_EXISTING_LINE
  );
  const findStatus = getFormField(state, ONE_TOUCH_SWITCH_SECTION, FIND_STATUS);
  const providerNotListed = getFormField(
    state,
    ONE_TOUCH_SWITCH_SECTION,
    PROVIDER_NOT_LISTED
  );

  const isExistingLineFalse = existingLine === false;
  const isFindStatusSuccess = findStatus === 'SUCCESS' || findStatus === 'FAIL';
  const isProviderNotListed = providerNotListed;

  return isExistingLineFalse || isFindStatusSuccess || isProviderNotListed;
};
