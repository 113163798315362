import createAddressPickerActions from 'redux/modules/AddressPickerNew/actions';
import {
  ABOUT_YOU_SECTION,
  ADDRESS_PICKER_PREFIX_BILLING,
  ADDRESS_PICKER_PREFIX_PREVIOUS,
  REFERRAL_AND_LEGAL_SECTION,
  DIRECT_DEBIT_SECTION,
  ONE_TOUCH_SWITCH_SECTION,
} from './constants';
import * as types from './types';

export const setFormPristine = (form, pristine = false) => ({
  type: types.DETAILS_FORM_SET_PRISTINE,
  form,
  pristine,
});

export const addressPickerBillingActions = createAddressPickerActions(
  ADDRESS_PICKER_PREFIX_BILLING
);
export const addressPickerPreviousActions = createAddressPickerActions(
  ADDRESS_PICKER_PREFIX_PREVIOUS
);

export const setOptOutMarketing = (checked) => ({
  type: types.SET_OPT_OUT_MARKETING,
  checked,
});

// Referral and legal form
export const updateReferralAndLegalForm = (field, value) => ({
  type: types.DETAILS_FORM_UPDATE,
  form: REFERRAL_AND_LEGAL_SECTION,
  field,
  value,
});

export const setValidationReferralAndLegal = (valid, fields) => ({
  type: types.DETAILS_FORM_FORM_SET_VALIDATION,
  form: REFERRAL_AND_LEGAL_SECTION,
  valid,
  fields,
});

export const termsAndConditionsUpdate = (checked) => ({
  type: types.TERMS_AND_CONDITIONS_UPDATE,
  checked,
});

export const eeccContractLinkClicked = () => ({
  type: types.EECC_CONTRACT_LINK_CLICKED,
});

// Direct debit form

export const updateDirectDebitForm = (field, value) => ({
  type: types.DETAILS_FORM_UPDATE,
  form: DIRECT_DEBIT_SECTION,
  field,
  value,
});

export const setValidationDirectDebitForm = (valid, fields) => ({
  type: types.DETAILS_FORM_FORM_SET_VALIDATION,
  form: DIRECT_DEBIT_SECTION,
  valid,
  fields,
});

export const setDirectDebitInitialName = (value) => ({
  type: types.UPDATE_DIRECT_DEBIT_NAME,
  value,
});

// About you form
export const updateFormAboutYou = (field, value) => {
  return {
    type: types.DETAILS_FORM_UPDATE,
    form: ABOUT_YOU_SECTION,
    field,
    value,
  };
};

export const setFormAboutYouLoading = (loading) => ({
  type: types.DETAILS_FORM_SET_LOADING,
  form: ABOUT_YOU_SECTION,
  loading,
});

export const setFormAboutYouValidation = (valid, fields) => ({
  type: types.DETAILS_FORM_FORM_SET_VALIDATION,
  form: ABOUT_YOU_SECTION,
  valid,
  fields,
});

// One Touch Switching Form

export const updateFormOneTouchSwitch = (field, value) => ({
  type: types.DETAILS_FORM_UPDATE,
  form: ONE_TOUCH_SWITCH_SECTION,
  field,
  value,
});

export const clearOneTouchSwitchForm = () => ({
  type: types.CLEAR_OTS_FORM,
});

export const updateBothFormsOneTouchSwitchAndReferallAndLegal = (
  firstFormToUpdate,
  secondFormToUpdate
) => ({
  type: types.UPDATE_BOTH_FORMS,
  payload: { firstFormToUpdate, secondFormToUpdate },
});

export const setOneTouchSwitchFormValidation = (valid, fields) => ({
  type: types.DETAILS_FORM_FORM_SET_VALIDATION,
  form: ONE_TOUCH_SWITCH_SECTION,
  valid,
  fields,
});

// Form errors
export const setFormErrors = (errors) => ({
  type: types.SET_FORM_ERRORS,
  errors,
});

export const deleteFormError = (id) => ({
  type: types.DELETE_FORM_ERROR,
  id,
});

// Generic
export const getFormInitialData = () => ({
  type: types.GET_FORM_INITIAL_DATA,
});

export const setCreditCheck = (shouldCreditCheck) => ({
  type: types.SET_CREDIT_CHECK,
  shouldCreditCheck,
});

export const submitDetailsForm = () => ({
  type: types.DETAILS_FORM_SUBMIT,
});

export const checkPaperBilling = () => ({
  type: types.CHECK_PAPER_BILLING,
});

export const validateDetailsForm = () => ({
  type: types.DETAILS_FORM_VALIDATE,
});

export const sectionSubmissionFailed = (error, errorType = null) => ({
  type: types.FORM_SECTION_SUBMISSION_FAILED,
  error,
  errorType,
});

export const touchDetailsForm = () => ({
  type: types.TOUCH_DETAILS_FORM,
});

export const setScrollToError = (shouldScroll) => ({
  type: types.SET_SCROLL_TO_ERROR,
  shouldScroll,
});

export const setPaperBillingModal = (open, variant) => ({
  type: types.SET_PAPER_BILLING_MODAL,
  open,
  variant,
});

export const requestEeccContract = () => ({
  type: types.DETAILS_REQUEST_EECC_CONTRACT,
});

export const requestEeccContractSuccess = () => ({
  type: types.DETAILS_REQUEST_EECC_CONTRACT_SUCCESS,
});

export const requestEeccContractFailure = (error) => ({
  type: types.DETAILS_REQUEST_EECC_CONTRACT_FAILURE,
  error,
});

export const contractGenerationLoading = () => ({
  type: types.CONTRACT_GENERATION_LOADING,
});

export const contractGenerationSuccess = () => ({
  type: types.CONTRACT_GENERATION_SUCCESS,
});

export const contractGenerationFailure = () => ({
  type: types.CONTRACT_GENERATION_FAILURE,
});
