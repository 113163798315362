import { PRODUCT_MOBILE } from 'app/redux/modules/Bundles/constants';
import { sumPrices } from 'app/redux/utils/priceUtils';
import { getMobileTariffPrice } from 'redux/modules/Mobile/lib';
import { copyMapper } from 'redux/utils/copyMapper';

const getSim = (mobile, index, mobiles) => {
  const { tariff } = mobile;
  const price = getMobileTariffPrice(tariff, index, mobiles);

  return {
    tariff: tariff.name || copyMapper(`mobile.tariff.label.${tariff.label}`),
    price,
  };
};

const getItems = (sims) => {
  const items = {};

  sims.forEach(({ tariff }) => {
    if (!items[tariff]) {
      items[tariff] = 1;
    } else {
      items[tariff]++;
    }
  });

  return Object.entries(items).map(
    ([tariff, amount]) => `${tariff} SIM x${amount}`
  );
};

const transformMobiles = (mobiles) => {
  if (!mobiles) {
    return null;
  }

  const sims = mobiles.map(getSim);
  const items = getItems(sims);
  const price = sumPrices(sims.map(({ price }) => price));

  return {
    discountedPrice: null,
    id: PRODUCT_MOBILE,
    items,
    oneTimeCost: null,
    price,
  };
};

export { transformMobiles };
