import { call, put, all, select } from 'redux-saga/effects';
import { uniqueId } from 'lodash';

import { BANK_ACCOUNT_DETAILS_ERROR_MESSAGE } from 'app/constants/validation';

import {
  setFormErrors,
  setScrollToError,
  setValidationDirectDebitForm,
  setValidationReferralAndLegal,
  touchDetailsForm,
} from '../actions';
import {
  getDirectDebitFieldsErrors,
  getFormErrors,
  getReferralAndLegalFieldsErrors,
} from '../api';
import { directDebitSection, referralAndLegalSection } from '../form';

import { formValidate as formValidateAboutYou } from './aboutYou';
import { formValidate as formValidateDirectDebitSection } from './directDebitSection';
import { formValidate as formValidateReferralAndLegal } from './referralAndLegal';
import { formValidate as formValidateOneTouchSwitch } from './oneTouchSwitch';
const bankApiErrorFields = [
  directDebitSection.DIRECT_DEBIT_ACCOUNT_NUMBER,
  directDebitSection.DIRECT_DEBIT_SORT_CODE,
];

const { REFERRER_ACCOUNT } = referralAndLegalSection;

const INVALID_REFERRER_ERROR_TYPE = 'InvalidReferrerError';

function* validateForm() {
  yield all([
    formValidateAboutYou(),
    formValidateDirectDebitSection(),
    formValidateReferralAndLegal(),
    formValidateOneTouchSwitch(),
  ]);
}

function* validateAndTouchForm() {
  yield call(validateForm);
  yield put(touchDetailsForm());
}

function* addFormError({ error }) {
  let errors = yield select(getFormErrors);

  const identifiedError = { id: uniqueId('requestError'), message: error };

  if (errors) {
    errors.push(identifiedError);
  } else {
    errors = [identifiedError];
  }

  yield put(setFormErrors(errors));
}

function* deleteFormError({ id }) {
  let currentErrors = yield select(getFormErrors);

  if (!currentErrors) {
    return;
  }

  let errors = currentErrors.filter(({ id: errorId }) => errorId !== id);

  if (!errors.length) {
    errors = null;
  }

  yield put(setFormErrors(errors));
}

function* handleApiFieldsErrors({ error, errorType }) {
  if (error === BANK_ACCOUNT_DETAILS_ERROR_MESSAGE) {
    const fieldsErrors = yield select(getDirectDebitFieldsErrors);

    yield put(
      setValidationDirectDebitForm(false, [
        ...fieldsErrors.filter(({ field }) =>
          bankApiErrorFields.includes(field)
        ),
        ...bankApiErrorFields.map((fieldKey) => ({
          field: fieldKey,
          valid: false,
          reason: BANK_ACCOUNT_DETAILS_ERROR_MESSAGE,
        })),
      ])
    );

    yield put(setScrollToError(true));
  }

  if (errorType === INVALID_REFERRER_ERROR_TYPE) {
    const fieldsErrors = yield select(getReferralAndLegalFieldsErrors);

    yield put(
      setValidationReferralAndLegal(false, [
        ...fieldsErrors.filter(({ field }) => field === REFERRER_ACCOUNT),
        {
          field: REFERRER_ACCOUNT,
          valid: false,
          reason: error,
        },
      ])
    );

    yield put(setScrollToError(true));
  }
}

export {
  validateForm,
  validateAndTouchForm,
  addFormError,
  deleteFormError,
  handleApiFieldsErrors,
  INVALID_REFERRER_ERROR_TYPE,
};
