const IS_REFERRAL = 'isReferral';
const REFERRER_NAME_FIRST = 'referrerNameFirst';
const REFERRER_NAME_LAST = 'referrerNameLast';
const REFERRER_ACCOUNT = 'referrerAccount';
export const TERMS_AND_CONDITIONS = 'termsAndConditions';

export const referralAndLegalSection = {
  IS_REFERRAL,
  REFERRER_NAME_FIRST,
  REFERRER_NAME_LAST,
  REFERRER_ACCOUNT,
  TERMS_AND_CONDITIONS,
};

export const ABOUT_YOU_TITLE = 'AboutYouTitle';
export const ABOUT_YOU_NAME_FIRST = 'AboutYouNameFirst';
export const ABOUT_YOU_NAME_LAST = 'AboutYouNameLast';
export const ABOUT_YOU_DATE_OF_BIRTH = 'AboutYouDateOfBirth';
export const ABOUT_YOU_CONTACT_NUMBER = 'AboutYouContactNumber';
export const ABOUT_YOU_EMAIL = 'AboutYouEmail';
export const ABOUT_YOU_PAPER_BILLING_CONFIRM = 'AboutYouPaperBillingConfirm';

export const ABOUT_YOU_ADDITIONAL_HOLDER_SELECT =
  'AboutYouAdditionalHolderSelect';
export const ABOUT_YOU_ADDITIONAL_TITLE = 'AboutYouAdditionalTitle';
export const ABOUT_YOU_ADDITIONAL_NAME_FIRST = 'AboutYouAdditionalNameFirst';
export const ABOUT_YOU_ADDITIONAL_NAME_LAST = 'AboutYouAdditionalNameLast';
export const ABOUT_YOU_ADDITIONAL_DATE_OF_BIRTH =
  'AboutYouAdditionalDateOfBirth';

export const ABOUT_YOU_CBC_ADDITIONAL_SELECT = 'AboutYouCbcAdditionalSelect';
export const ABOUT_YOU_CBC_ADDITIONAL_NAME_FIRST =
  'AboutYouCbcAdditionalNameFirst';
export const ABOUT_YOU_CBC_ADDITIONAL_NAME_LAST =
  'AboutYouCbcAdditionalNameLast';
export const ABOUT_YOU_CBC_ADDITIONAL_DATE_OF_BIRTH =
  'AboutYouCbcAdditionalDateOfBirth';

export const ABOUT_YOU_PASSWORD = 'AboutYouPassword';

export const ABOUT_YOU_RECENT_MOVE = 'AboutYouRecentMove';
export const ABOUT_YOU_OWNER = 'AboutYouOwner';
export const ABOUT_YOU_PREVIOUS_ADDRESS = 'AboutYouPreviousAddress';

export const aboutYouSection = {
  ABOUT_YOU_TITLE,
  ABOUT_YOU_NAME_FIRST,
  ABOUT_YOU_NAME_LAST,
  ABOUT_YOU_DATE_OF_BIRTH,
  ABOUT_YOU_CONTACT_NUMBER,
  ABOUT_YOU_EMAIL,
  ABOUT_YOU_PASSWORD,
  ABOUT_YOU_PAPER_BILLING_CONFIRM,

  ABOUT_YOU_ADDITIONAL_HOLDER_SELECT,
  ABOUT_YOU_ADDITIONAL_TITLE,
  ABOUT_YOU_ADDITIONAL_NAME_FIRST,
  ABOUT_YOU_ADDITIONAL_NAME_LAST,
  ABOUT_YOU_ADDITIONAL_DATE_OF_BIRTH,

  ABOUT_YOU_CBC_ADDITIONAL_SELECT,
  ABOUT_YOU_CBC_ADDITIONAL_NAME_FIRST,
  ABOUT_YOU_CBC_ADDITIONAL_NAME_LAST,
  ABOUT_YOU_CBC_ADDITIONAL_DATE_OF_BIRTH,

  ABOUT_YOU_RECENT_MOVE,
  ABOUT_YOU_OWNER,
  ABOUT_YOU_PREVIOUS_ADDRESS,
};

const DIRECT_DEBIT_NAME = 'directDebitName';
const DIRECT_DEBIT_SORT_CODE = 'directDebitSortCode';
const DIRECT_DEBIT_ACCOUNT_NUMBER = 'directDebitAccountNumber';
const BILLING_ADDRESS = 'billingAddress';
const BILLING_ADDRESS_SAME = 'billingAddressSame';

export const directDebitSection = {
  DIRECT_DEBIT_NAME,
  DIRECT_DEBIT_SORT_CODE,
  DIRECT_DEBIT_ACCOUNT_NUMBER,
  BILLING_ADDRESS,
  BILLING_ADDRESS_SAME,
};

export const HAS_EXISTING_LINE = 'hasExistingLine';
export const CURRENT_PROVIDER = 'currentProvider';
export const PROVIDER_NOT_LISTED = 'providerNotListed';
export const FIND_STATUS = 'findStatus';
export const MATCH_ID = 'matchId';
export const SWITCHING_REFERENCE = 'switchingReference';
export const BROADBAND_REFERENCE = 'broadbandReference';

export const oneTouchSwitchSection = {
  HAS_EXISTING_LINE,
  CURRENT_PROVIDER,
  PROVIDER_NOT_LISTED,
  FIND_STATUS,
  MATCH_ID,
  SWITCHING_REFERENCE,
  BROADBAND_REFERENCE,
};

// UI ordered fields are essential input for scroll-to-error feature
export const ORDERED_FORM_FIELDS = [
  ABOUT_YOU_TITLE,
  ABOUT_YOU_NAME_FIRST,
  ABOUT_YOU_NAME_LAST,
  ABOUT_YOU_DATE_OF_BIRTH,
  ABOUT_YOU_CONTACT_NUMBER,
  ABOUT_YOU_EMAIL,
  ABOUT_YOU_PASSWORD,
  ABOUT_YOU_PAPER_BILLING_CONFIRM,
  ABOUT_YOU_ADDITIONAL_HOLDER_SELECT,
  ABOUT_YOU_ADDITIONAL_TITLE,
  ABOUT_YOU_ADDITIONAL_NAME_FIRST,
  ABOUT_YOU_ADDITIONAL_NAME_LAST,
  ABOUT_YOU_ADDITIONAL_DATE_OF_BIRTH,
  ABOUT_YOU_CBC_ADDITIONAL_SELECT,
  ABOUT_YOU_CBC_ADDITIONAL_NAME_FIRST,
  ABOUT_YOU_CBC_ADDITIONAL_NAME_LAST,
  ABOUT_YOU_CBC_ADDITIONAL_DATE_OF_BIRTH,
  ABOUT_YOU_RECENT_MOVE,
  ABOUT_YOU_OWNER,
  ABOUT_YOU_PREVIOUS_ADDRESS,
  DIRECT_DEBIT_NAME,
  DIRECT_DEBIT_SORT_CODE,
  DIRECT_DEBIT_ACCOUNT_NUMBER,
  BILLING_ADDRESS,
  BILLING_ADDRESS_SAME,
  IS_REFERRAL,
  REFERRER_NAME_FIRST,
  REFERRER_NAME_LAST,
  REFERRER_ACCOUNT,
  TERMS_AND_CONDITIONS,
  ...Object.values(oneTouchSwitchSection),
];
