import OneTouchSwitch from 'app/components/modules/YourDetails/OneTouchSwitchSection';
import {
  STORE_NAME,
  ONE_TOUCH_SWITCH_SECTION,
  REFERRAL_AND_LEGAL_SECTION,
} from 'redux/modules/YourDetails/constants';
import { connector } from 'redux/utils/connector';
import { EntryFormApi } from 'redux/modules/EntryForm/api';
import { TERMS_AND_CONDITIONS } from '../form';
import { OrderSummaryApi } from '../../OrderSummary/api';

import {
  termsAndConditionsUpdate,
  updateFormOneTouchSwitch,
  updateBothFormsOneTouchSwitchAndReferallAndLegal,
} from '../actions';
import * as api from 'redux/modules/YourDetails/api';

const mapStateToProps = (state) => {
  return {
    fields: api.getFormFields(state, ONE_TOUCH_SWITCH_SECTION),
    termsAndConditionsState: api.getFormField(
      state,
      REFERRAL_AND_LEGAL_SECTION,
      TERMS_AND_CONDITIONS
    ),
    valid: api.getFormValid(state, ONE_TOUCH_SWITCH_SECTION),
    errors: api.getFormValidationErrors(state, ONE_TOUCH_SWITCH_SECTION),
    supplyAddress: EntryFormApi.supplyAddress(state),
    surname: api.getFirstHolderName(state).surname,
    hasBroadband: OrderSummaryApi.getHasBroadband(state),
    orderType: OrderSummaryApi.getHasHomePhoneAndBrodband(state),
    numberToPort: OrderSummaryApi.getHomePhoneNumber(state),
    isSurnameValid: api.getSurnameValid(state),
  };
};
const OneTouchSwitchContainer = connector(STORE_NAME, mapStateToProps, {
  updateForm: updateFormOneTouchSwitch,
  onTermsChecked: termsAndConditionsUpdate,
  updateBothForms: updateBothFormsOneTouchSwitchAndReferallAndLegal,
})(OneTouchSwitch);

export { OneTouchSwitchContainer };
