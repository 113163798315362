import { compact, get, isEmpty } from 'lodash';

import { sumPrices } from 'app/redux/utils/priceUtils';
import { PRODUCT_ENERGY } from 'app/redux/modules/Bundles/constants';
import {
  TARIFF_LABELS,
  TARIFF_TYPES,
} from 'app/redux/modules/Energy/Quote/constants';

const MONTHS_IN_THE_YEAR = 12;
const DISCOUNTED_TARIFFS = [
  TARIFF_LABELS.DOUBLE_GOLD,
  TARIFF_LABELS.DOUBLE_GOLD_FIXED,
  TARIFF_LABELS.GOLD,
];
const TARIFF_NAMES = {
  fixed: 'Fixed tariff',
  variable: 'Variable tariff',
};

const getIsFixed = (electricityQuote, gasQuote) =>
  get(electricityQuote, 'tariff.type', null) === TARIFF_TYPES.FIXED ||
  get(gasQuote, 'tariff.type', null) === TARIFF_TYPES.FIXED;

const getTariffName = (isFixed) =>
  isFixed ? TARIFF_NAMES.fixed : TARIFF_NAMES.variable;

const getMonthlyPrice = (quote) =>
  quote
    ? {
        ...quote.personalProjection,
        value: quote.personalProjection.value / MONTHS_IN_THE_YEAR,
      }
    : null;

const getTariffPrice = (quote, otherQuotes) => {
  if (!quote || !DISCOUNTED_TARIFFS.includes(quote.tariff.name)) {
    return null;
  }

  const valueQuote = otherQuotes
    .filter(({ type }) => type === quote.type)
    .find(({ tariff }) => tariff.name === 'Value');

  if (!valueQuote) {
    return null;
  }

  return getMonthlyPrice(valueQuote);
};

const getMainFeatureLabel = ({ hasElectricity, hasGas }) => {
  let label = 'Gas and electricity';

  if (!hasGas) {
    label = 'Electricity';
  } else if (!hasElectricity) {
    label = 'Gas';
  }

  return label;
};

const transformEnergy = ({ electricity, gas }, quotesContainer) => {
  if (!electricity && !gas) {
    return null;
  }

  const electricityQuote = get(electricity, 'quote', null);
  const gasQuote = get(gas, 'quote', null);
  const isFixed = getIsFixed(electricityQuote, gasQuote);

  const mainFeatureLabel = getMainFeatureLabel({
    hasElectricity: Boolean(electricity),
    hasGas: Boolean(gas),
  });
  const tariffName = getTariffName(isFixed);
  const features = [mainFeatureLabel, tariffName];

  const { quotes } = quotesContainer;

  const prices = compact([
    getMonthlyPrice(gasQuote),
    getMonthlyPrice(electricityQuote),
  ]);
  const tariffPrices = compact([
    getTariffPrice(electricityQuote, quotes),
    getTariffPrice(gasQuote, quotes),
  ]);
  const pricesSum = sumPrices(prices);
  const hasDiscount = !isEmpty(tariffPrices) || isFixed;

  return {
    discountedPrice: hasDiscount ? pricesSum : null,
    id: PRODUCT_ENERGY,
    items: features,
    oneTimeCost: null,
    price: hasDiscount ? sumPrices(tariffPrices) : pricesSum,
  };
};

export {
  transformEnergy,
  getMonthlyPrice,
  getTariffPrice,
  getMainFeatureLabel,
  DISCOUNTED_TARIFFS,
  TARIFF_NAMES,
};
