import DirectDebit from 'app/components/modules/YourDetails/DirectDebitSection';
import { EntryFormApi } from 'redux/modules/EntryForm/api';
import {
  STORE_NAME,
  DIRECT_DEBIT_SECTION,
  ABOUT_YOU_SECTION,
} from 'redux/modules/YourDetails/constants';
import { aboutYouSection } from 'redux/modules/YourDetails/form';
import {
  getFormFields,
  getFormValidationErrors,
  getFieldPristine,
  getShouldCreditCheck,
} from 'redux/modules/YourDetails/api';
import {
  setDirectDebitInitialName,
  updateDirectDebitForm,
} from 'redux/modules/YourDetails/actions';
import { connector } from 'redux/utils/connector';

const mapStateToProps = (state) => {
  const {
    [aboutYouSection.ABOUT_YOU_NAME_FIRST]: accountHolderNameFirst,
    [aboutYouSection.ABOUT_YOU_NAME_LAST]: accountHolderNameLast,
  } = getFormFields(state, ABOUT_YOU_SECTION);

  return {
    values: getFormFields(state, DIRECT_DEBIT_SECTION),
    errors: getFormValidationErrors(state, DIRECT_DEBIT_SECTION),
    pristine: getFieldPristine(state, DIRECT_DEBIT_SECTION),
    supplyAddress: EntryFormApi.supplyAddress(state),
    accountHolderName: `${accountHolderNameFirst} ${accountHolderNameLast}`,
    shouldCreditCheck: getShouldCreditCheck(state),
  };
};

const DirectDebitSectionContainer = connector(STORE_NAME, mapStateToProps, {
  onFormChange: updateDirectDebitForm,
  updateName: setDirectDebitInitialName,
})(DirectDebit);

export { DirectDebitSectionContainer };
