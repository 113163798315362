import { call, put, select } from 'redux-saga/effects';

import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import { translateBackendErrorToMessage } from 'redux/modules/Shared/BackendParser';

import {
  setFormPristine,
  setValidationReferralAndLegal,
  sectionSubmissionFailed,
  updateReferralAndLegalForm,
} from '../actions';
import {
  getContractGenerationStatus,
  getReferralAndLegalFormFields,
} from '../api';
import {
  CONTRACT_LOADING_MESSAGE,
  CONTRACT_REQUEST_FAILED_ERROR,
  REFERRAL_AND_LEGAL_SECTION,
  REQUEST_STATUS,
} from '../constants';
import { TERMS_AND_CONDITIONS, referralAndLegalSection } from '../form';
import { putReferrerDetails } from '../services';
import { validateReferralAndLegal } from '../validation/referralAndLegal';
import { getClient } from 'app/lib/analytics';
import { EVENTS } from 'app/lib/analytics/constants';
import {
  openErrorGlobalDialog,
  openGlobalDialog,
} from 'redux/modules/GlobalDialog/actions';
import { contractDownloadRequest } from 'redux/modules/OrderSummary/actions';

const {
  IS_REFERRAL,
  REFERRER_NAME_FIRST,
  REFERRER_NAME_LAST,
  REFERRER_ACCOUNT,
} = referralAndLegalSection;

function* formValidate() {
  const fields = yield select(getReferralAndLegalFormFields);
  const validation = validateReferralAndLegal(fields);
  yield put(setValidationReferralAndLegal(validation.valid, validation.fields));
}

function* formSubmit() {
  const { client: analytics } = getClient();

  yield put(setFormPristine(REFERRAL_AND_LEGAL_SECTION, false));

  const fields = yield select(getReferralAndLegalFormFields);

  const {
    [IS_REFERRAL]: hasReferrer,
    [REFERRER_NAME_FIRST]: referrerNameFirst,
    [REFERRER_NAME_LAST]: referrerNameLast,
    [REFERRER_ACCOUNT]: referrerAccount,
  } = fields;
  let referrerName;
  if (referrerNameFirst && referrerNameLast) {
    referrerName = `${referrerNameFirst} ${referrerNameLast}`;
  }

  if (!hasReferrer) {
    return;
  }
  try {
    if (!referrerAccount) {
      yield call(putReferrerDetails, referrerName);
    } else {
      yield call(putReferrerDetails, referrerName, referrerAccount);
    }

    analytics.track(EVENTS.REFERRER_SUBMITTED);
  } catch (error) {
    const message = translateBackendErrorToMessage(error);
    Sentry.log(error, ERRORS.UPDATE_ABOUT_YOU_CLUBHOUSE);
    return yield put(
      sectionSubmissionFailed(message, error.response?.data?.type)
    );
  }
}

function* handleTermsAndConditionsUpdate({ checked }) {
  const contractGenerationRequestStatus = yield select(
    getContractGenerationStatus
  );

  if (contractGenerationRequestStatus === REQUEST_STATUS.ERROR) {
    return yield put(openErrorGlobalDialog(CONTRACT_REQUEST_FAILED_ERROR));
  }

  yield put(updateReferralAndLegalForm(TERMS_AND_CONDITIONS, checked));
}

function* handleEeccTermsClicked() {
  const contractGenerationRequestStatus = yield select(
    getContractGenerationStatus
  );

  if (contractGenerationRequestStatus === REQUEST_STATUS.LOADING) {
    return yield put(openGlobalDialog('Try again', CONTRACT_LOADING_MESSAGE));
  }

  yield put(contractDownloadRequest());
}

export {
  formValidate,
  formSubmit,
  handleTermsAndConditionsUpdate,
  handleEeccTermsClicked,
};
