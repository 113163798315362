import { regexLatinChars } from 'redux/modules/YourDetails/validation/isNameValid';
import { directDebitSection } from 'redux/modules/YourDetails/form';

const regexSortCode = /^[0-9]{2}-[0-9]{2}-[0-9]{2}$/;
const regexAccountNumber = /^[0-9]{8}$/;

const {
  DIRECT_DEBIT_NAME,
  DIRECT_DEBIT_SORT_CODE,
  DIRECT_DEBIT_ACCOUNT_NUMBER,
  BILLING_ADDRESS,
  BILLING_ADDRESS_SAME,
} = directDebitSection;

const errorMessages = {
  required: 'This field is required',
  invalidAccountNumber: 'Account number is invalid',
  invalidSortCode: 'Sort code is invalid',
  invalidName: 'Please enter a valid name (latin characters only)',
  nameMatch: 'Name does not match account holder or additional account holder',
};

const validateSortCode = (value) => {
  if (!value?.length) {
    return {
      valid: false,
      reason: errorMessages.required,
    };
  }

  const valid = regexSortCode.test(value);
  if (!valid) {
    return {
      valid: false,
      reason: errorMessages.invalidSortCode,
    };
  }

  return {
    valid: true,
  };
};

const validateName = (
  value,
  accountHolderName,
  additionalAccountHolderName = ''
) => {
  const strippedValue = value?.replace(/&/g, '');

  if (!strippedValue?.length) {
    return {
      valid: false,
      reason: errorMessages.required,
    };
  }

  const valid = regexLatinChars.test(strippedValue);

  if (!valid) {
    return {
      valid: false,
      reason: errorMessages.invalidName,
    };
  }

  const checkNameMatch = (name, comparison) => {
    if (comparison.trim().length === 0) return false;

    return name.toUpperCase().indexOf(comparison.toUpperCase()) > -1;
  };
  const nameMatch = checkNameMatch(strippedValue, accountHolderName);
  const additionalNameMatch = checkNameMatch(
    strippedValue,
    additionalAccountHolderName
  );

  if (!nameMatch && !additionalNameMatch) {
    return {
      valid: false,
      reason: errorMessages.nameMatch,
    };
  }

  return {
    valid: true,
  };
};

const validateAccountNumber = (value) => {
  if (!value?.length) {
    return {
      valid: false,
      reason: errorMessages.required,
    };
  }

  const valid = regexAccountNumber.test(value);
  if (!valid) {
    return {
      valid: false,
      reason: errorMessages.invalidAccountNumber,
    };
  }

  return {
    valid: true,
  };
};

const validateBillingAddress = (value) => {
  const isValid = value;

  if (!isValid) {
    return {
      valid: false,
      reason: errorMessages.required,
    };
  }

  return {
    valid: true,
  };
};

const validationDirectDebitSection = (fields, props = {}) => {
  const {
    accountHolderName,
    additionalAccountHolderName,
    billingAddress,
  } = props;
  let results = [
    {
      field: DIRECT_DEBIT_NAME,
      ...validateName(
        fields[DIRECT_DEBIT_NAME],
        accountHolderName,
        additionalAccountHolderName
      ),
    },
    {
      field: DIRECT_DEBIT_SORT_CODE,
      ...validateSortCode(fields[DIRECT_DEBIT_SORT_CODE]),
    },
    {
      field: DIRECT_DEBIT_ACCOUNT_NUMBER,
      ...validateAccountNumber(fields[DIRECT_DEBIT_ACCOUNT_NUMBER]),
    },
  ];

  if (!fields[BILLING_ADDRESS_SAME]) {
    results = [
      ...results,
      {
        field: BILLING_ADDRESS,
        ...validateBillingAddress(billingAddress),
      },
    ];
  }

  const isValid = results.every((result) => result.valid === true);

  return {
    valid: isValid,
    fields: results,
  };
};

export {
  errorMessages,
  validationDirectDebitSection,
  validateSortCode,
  validateName,
  validateAccountNumber,
  validateBillingAddress,
};
