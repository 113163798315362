export const STORE_NAME = 'YourDetails';

export const ADDRESS_PICKER_PREFIX_BILLING = 'YourDetailsBilling';
export const ADDRESS_PICKER_PREFIX_PREVIOUS = 'YourDetailsPrevious';
export const ADDRESS_PICKER_KEY_BILLING = 'billingAddress';
export const ADDRESS_PICKER_KEY_PREVIOUS = 'previousAddress';

export const ABOUT_YOU_TITLES = [
  'Mr',
  'Miss',
  'Mrs',
  'Ms',
  'Mx',
  'Dr',
  'Prof',
  'Rev',
  'Sir',
  'Dame',
  'Lord',
  'Lady',
];

export const OCCUPANCY = {
  OWNERSHIP: 'OWNERSHIP',
  TENANCY: 'TENANCY',
};

// dialog variants for paper billing,
// energy only is special and doesn't give a fee
export const PAPER_BILLING_EO = 'PAPER_BILLING_EO';
export const PAPER_BILLING_DEFAULT = 'PAPER_BILLING_DEFAULT';

export const BANK_ACCOUNT_VERIFICATION_ERROR =
  'Account verification failed, please use only your account details matching your billing address.';
export const CONTRACT_LOADING_MESSAGE =
  "We're still loading your Broadband & Mobile Contract Summary. Please wait a few seconds and try again.";
export const CONTRACT_REQUEST_FAILED_ERROR =
  'Sorry, we were unable to get your Broadband & Mobile Contract Summary. Please refresh the page, wait 10 seconds and try again.';

export const houseAccounts = [
  'TDM006',
  'TEL0001',
  'TML0000',
  'TEL0000',
  '00WEBS',
  '00WHICH',
  '01WHICH',
  'CFR000',
  'CGA000',
  'DUMMY',
  'HUW001',
  'HUW002',
  'HUW003',
  'HUW004',
  'IR0000',
  'SALE00',
  'SALE01',
  'SALE99',
  'SDP000',
];
export const partnerOrCustomerNumberRegEx = /^([0-9]{6,7}|[A-Z]{1,2}[0-9]{4,5})$/;
export const PASSWORD_MIN_LENGTH = 8;

export const ABOUT_YOU_SECTION = 'aboutYouSection';
export const REFERRAL_AND_LEGAL_SECTION = 'referralAndLegalSection';
export const DIRECT_DEBIT_SECTION = 'directDebitSection';
export const ONE_TOUCH_SWITCH_SECTION = 'oneTouchSwitchSection';
export const FORM_ERRORS = 'formErrors';

export const FORM_SECTIONS = [
  ABOUT_YOU_SECTION,
  DIRECT_DEBIT_SECTION,
  REFERRAL_AND_LEGAL_SECTION,
  ONE_TOUCH_SWITCH_SECTION,
];

export const INVALID_FORM_ERROR_MESSAGE =
  'Please correct the errors on this form';

export const REQUEST_STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};
