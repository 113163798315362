import { compact, get } from 'lodash';

import { PRODUCT_BROADBAND } from 'redux/modules/Bundles/constants';
import { ADD_ON_CASHBACK_CARD } from 'app/redux/modules/OrderSummary/constants';
import { sumPrices } from 'app/redux/utils/priceUtils';
import { formatPrice } from 'app/redux/utils/formatter';

import { transformCashback } from './cashback';
import { transformBillProtector } from './billProtector';
import { transformEnergy } from './energy';
import { transformBroadband } from './broadband';
import { transformMobiles } from './mobile';
import { transformInsurance } from './insurance';

const getBreakdown = (breakdown, productName) =>
  breakdown?.services?.find(({ name }) => name === productName);

const transformAddOns = (products, state) => {
  const billProtectorState = get(products, 'billProtector', null);

  const cashback = transformCashback(state);
  const billProtector = transformBillProtector(billProtectorState);

  return compact([cashback, billProtector]);
};

const transformServices = ({ products, quotes, breakdown }) => {
  const homePhone = get(products, 'homephone', null);
  const broadband = get(products, 'broadband', null);
  const mobiles = get(products, 'mobiles', null);
  const electricity = get(products, 'electricity', null);
  const gas = get(products, 'gas', null);
  const incomeProtector = get(products, 'incomeProtector', null);
  const boiler = get(products, 'boilerInsurance', null);
  const homeInsurance = get(products, 'homeInsurance', null);

  const breakdownBroadband = getBreakdown(breakdown, 'broadband');
  const breakdownHomePhone = getBreakdown(breakdown, 'homephone');

  const energyService = transformEnergy({ electricity, gas }, quotes);
  const broadbandService = transformBroadband(
    broadband,
    homePhone,
    breakdownBroadband,
    breakdownHomePhone
  );
  const mobileService = transformMobiles(mobiles);
  const insuranceService = transformInsurance({
    incomeProtector,
    boiler,
    homeInsurance,
  });

  return compact([
    energyService,
    broadbandService,
    mobileService,
    insuranceService,
  ]);
};
// 4 service discount - https://utilitywarehouse.slack.com/archives/C03L36BQMFC/p1711016099347099
// Extra discounts are applied to the total price on the FE, so we need to include them in the calculation of the discountedTotalPrice
const getProductTotals = (products, addOns, extraDiscounts) => {
  const formattedExtraDiscounts = extraDiscounts.map(({ amount }) => ({
    ...amount,
    // Discounts has to be subtracted from the total price
    value: amount.value * -1,
  }));

  const totalPrice = sumPrices([
    ...[...products, ...addOns].map(({ price }) => price),
    ...formattedExtraDiscounts,
  ]);

  const discountedTotalPrice = sumPrices([
    ...products.map(({ price, discountedPrice }) => discountedPrice ?? price),
    ...addOns.map(({ price }) => price),
    ...formattedExtraDiscounts,
  ]);

  return { totalPrice, discountedTotalPrice };
};

const translateOneTimeCost = (id, oneTimeCost) => {
  if (!oneTimeCost || oneTimeCost.value === 0) {
    return null;
  }

  const value = formatPrice(oneTimeCost);

  switch (id) {
    case PRODUCT_BROADBAND: {
      return {
        text: 'Plus',
        value,
        subtext: 'P&P on first bill',
      };
    }
    case ADD_ON_CASHBACK_CARD: {
      return {
        text: 'Plus',
        value,
        subtext: 'fee on first bill',
      };
    }
    default:
      return null;
  }
};

const getBasketItems = (services) => {
  if (!services) {
    return null;
  }
  return services.map(
    ({ discountedPrice, id, items, oneTimeCost, price, serviceRaw }) => ({
      id,
      items,
      priceValue: formatPrice(discountedPrice ?? price),
      oneTimeCost: translateOneTimeCost(id, oneTimeCost),
      // Added for Try Before You Buy promo reason
      // Maybe we can use this in other places in the future
      serviceRaw,
    })
  );
};

const getBasketDiscounts = (discounts) =>
  discounts.map(({ reason, amount }) => ({
    label: reason,
    value: formatPrice(amount),
  }));

export {
  getBasketItems,
  transformServices,
  transformAddOns,
  getProductTotals,
  getBreakdown,
  translateOneTimeCost,
  getBasketDiscounts,
};
