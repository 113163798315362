import {
  ADDRESS_PICKER_KEY_BILLING,
  STORE_NAME,
} from 'redux/modules/YourDetails/constants';
import { BillingAddressPicker } from 'app/components/modules/YourDetails/DirectDebitSection/BillingAddressSection/BillingAddressPicker/index';
import { addressPickerBillingActions } from 'redux/modules/YourDetails/actions';
import { connector } from 'redux/utils/connector';

export const BillingAddressPickerContainer = connector(
  STORE_NAME,
  (state) => ({
    addressPicker: state.getIn([STORE_NAME, ADDRESS_PICKER_KEY_BILLING]).toJS(),
  }),
  addressPickerBillingActions
)(BillingAddressPicker);
