import { ADD_ON_BILL_PROTECTOR } from 'app/redux/modules/OrderSummary/constants';

const transformBillProtector = (billProtector) => {
  if (!billProtector) {
    return null;
  }

  const { price } = billProtector;

  return {
    discountedPrice: null,
    id: ADD_ON_BILL_PROTECTOR,
    items: null,
    oneTimeCost: null,
    price,
  };
};

export { transformBillProtector };
