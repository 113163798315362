import moment from 'moment';

import validateEmail from 'redux/utils/validateEmail';
import {
  validateGBMobileNumber,
  validateGBLandlineNumber,
} from 'redux/utils/validateGBNumber';

import {
  aboutYouSection,
  ABOUT_YOU_ADDITIONAL_HOLDER_SELECT,
  ABOUT_YOU_CBC_ADDITIONAL_SELECT,
} from '../form';
import { isNameValid } from './isNameValid';
import { PASSWORD_MIN_LENGTH } from '../constants';

const REASONS = {
  ERROR_TITLE_REQUIRED: 'Your title is required',
  ERROR_DOB_REQUIRED: 'Date of birth is required',
  ERROR_DOB_INVALID: 'Please enter a valid date',
  ERROR_DOB_AGE_MIN: 'You must be 18 years or older',
  ERROR_DOB_AGE_MAX: 'Please verify the date entered is correct',

  ERROR_CONTACT_REQUIRED: 'A contact number is required',
  ERROR_CONTACT_INVALID: 'Please enter a valid landline or mobile number',

  ERROR_EMAIL_REQUIRED: 'An email address is required',
  ERROR_EMAIL_INVALID: 'Please enter a valid email address',
  ERROR_EMAIL_NO_MATCH: 'The email addresses entered do not match',

  ERROR_REQUIRED: 'This field is required',

  ERROR_PASSWORD_LEN: `Password must be at least ${PASSWORD_MIN_LENGTH} characters`,
  ERROR_PASSWORD_CHAR:
    'Password must contain one upper case letter and one number',
};

const REGEX_PASSWORD = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([\S\s]{8,})$/;

const validTitle = (value) => {
  if (!value) {
    return {
      valid: false,
      reason: REASONS.ERROR_TITLE_REQUIRED,
    };
  }

  return { valid: true };
};

const validName = (value) => {
  const { error: reason, isValid: valid } = isNameValid(value);
  if (!valid) {
    return {
      valid,
      reason,
    };
  }

  return {
    valid: true,
  };
};

const validDob = (value) => {
  if (!value) {
    return {
      valid: false,
      reason: REASONS.ERROR_DOB_REQUIRED,
    };
  }

  if (isNaN(new Date(value).valueOf())) {
    return {
      valid: false,
      reason: REASONS.ERROR_DOB_INVALID,
    };
  }

  const minDate = moment().subtract(110, 'years');
  const maxDate = moment().subtract(18, 'years');
  const momentDate = moment(value);

  if (momentDate.isAfter(maxDate, 'day')) {
    return {
      valid: false,
      reason: REASONS.ERROR_DOB_AGE_MIN,
    };
  }

  if (momentDate.isBefore(minDate, 'day')) {
    return {
      valid: false,
      reason: REASONS.ERROR_DOB_AGE_MAX,
    };
  }

  return { valid: true };
};

const validNumber = (value) => {
  if (!value) {
    return {
      valid: false,
      reason: REASONS.ERROR_CONTACT_REQUIRED,
    };
  }

  const validMobileNumber = validateGBMobileNumber(value);
  const validLandlineNumber = validateGBLandlineNumber(value);

  if (!validMobileNumber && !validLandlineNumber) {
    return {
      valid: false,
      reason: REASONS.ERROR_CONTACT_INVALID,
    };
  }

  return {
    valid: true,
  };
};

const validEmail = (value, required = false) => {
  if ((!value || value.length === 0) && required) {
    return {
      valid: false,
      reason: REASONS.ERROR_EMAIL_REQUIRED,
    };
  }

  if (!validateEmail(value)) {
    return {
      valid: false,
      reason: REASONS.ERROR_EMAIL_INVALID,
    };
  }

  return { valid: true };
};

const isBool = (value) => {
  return typeof value === 'boolean';
};

const validateRequiredBoolean = (value) => {
  const valid = isBool(value);

  return {
    valid,
    ...(valid ? {} : { reason: REASONS.ERROR_REQUIRED }),
  };
};

const validateRequired = (value) => {
  if (!value) {
    return {
      valid: false,
      reason: REASONS.ERROR_REQUIRED,
    };
  }

  return {
    valid: true,
  };
};

const validatePassword = (value) => {
  if (!value?.length || value.length < PASSWORD_MIN_LENGTH) {
    return {
      valid: false,
      reason: REASONS.ERROR_PASSWORD_LEN,
    };
  }

  if (!REGEX_PASSWORD.test(value)) {
    return {
      valid: false,
      reason: REASONS.ERROR_PASSWORD_CHAR,
    };
  }

  return {
    valid: true,
  };
};

const validationAboutYou = (fields, props) => {
  const { isTenant, previousAddress } = props;

  let results = [
    {
      field: aboutYouSection.ABOUT_YOU_TITLE,
      ...validTitle(fields[aboutYouSection.ABOUT_YOU_TITLE]),
    },
    {
      field: aboutYouSection.ABOUT_YOU_NAME_FIRST,
      ...validName(fields[aboutYouSection.ABOUT_YOU_NAME_FIRST]),
    },
    {
      field: aboutYouSection.ABOUT_YOU_NAME_LAST,
      ...validName(fields[aboutYouSection.ABOUT_YOU_NAME_LAST]),
    },
    {
      field: aboutYouSection.ABOUT_YOU_DATE_OF_BIRTH,
      ...validDob(fields[aboutYouSection.ABOUT_YOU_DATE_OF_BIRTH]),
    },
    {
      field: aboutYouSection.ABOUT_YOU_CONTACT_NUMBER,
      ...validNumber(fields[aboutYouSection.ABOUT_YOU_CONTACT_NUMBER]),
    },
    {
      field: aboutYouSection.ABOUT_YOU_EMAIL,
      ...validEmail(fields[aboutYouSection.ABOUT_YOU_EMAIL]),
    },
    {
      field: aboutYouSection.ABOUT_YOU_PASSWORD,
      ...validatePassword(fields[aboutYouSection.ABOUT_YOU_PASSWORD]),
    },
  ];

  if (!isTenant) {
    const newOwner = fields[aboutYouSection.ABOUT_YOU_OWNER];
    const recentMove = fields[aboutYouSection.ABOUT_YOU_RECENT_MOVE];
    results.push({
      field: aboutYouSection.ABOUT_YOU_RECENT_MOVE,
      ...validateRequiredBoolean(recentMove),
    });

    if (recentMove) {
      results.push({
        field: aboutYouSection.ABOUT_YOU_OWNER,
        ...validateRequiredBoolean(newOwner),
      });
    }

    if (newOwner === false && recentMove === true) {
      results.push({
        field: aboutYouSection.ABOUT_YOU_PREVIOUS_ADDRESS,
        ...validateRequired(previousAddress),
      });
    }
  }

  if (fields[ABOUT_YOU_ADDITIONAL_HOLDER_SELECT]) {
    results = [
      ...results,
      {
        field: aboutYouSection.ABOUT_YOU_ADDITIONAL_TITLE,
        ...validTitle(fields[aboutYouSection.ABOUT_YOU_ADDITIONAL_TITLE]),
      },
      {
        field: aboutYouSection.ABOUT_YOU_ADDITIONAL_NAME_FIRST,
        ...validName(fields[aboutYouSection.ABOUT_YOU_ADDITIONAL_NAME_FIRST]),
      },
      {
        field: aboutYouSection.ABOUT_YOU_ADDITIONAL_NAME_LAST,
        ...validName(fields[aboutYouSection.ABOUT_YOU_ADDITIONAL_NAME_LAST]),
      },
      {
        field: aboutYouSection.ABOUT_YOU_ADDITIONAL_DATE_OF_BIRTH,
        ...validDob(fields[aboutYouSection.ABOUT_YOU_ADDITIONAL_DATE_OF_BIRTH]),
      },
    ];
  }

  if (fields[ABOUT_YOU_CBC_ADDITIONAL_SELECT]) {
    results = [
      ...results,
      {
        field: aboutYouSection.ABOUT_YOU_CBC_ADDITIONAL_NAME_FIRST,
        ...validName(
          fields[aboutYouSection.ABOUT_YOU_CBC_ADDITIONAL_NAME_FIRST]
        ),
      },
      {
        field: aboutYouSection.ABOUT_YOU_CBC_ADDITIONAL_NAME_LAST,
        ...validName(
          fields[aboutYouSection.ABOUT_YOU_CBC_ADDITIONAL_NAME_LAST]
        ),
      },
      {
        field: aboutYouSection.ABOUT_YOU_CBC_ADDITIONAL_DATE_OF_BIRTH,
        ...validDob(
          fields[aboutYouSection.ABOUT_YOU_CBC_ADDITIONAL_DATE_OF_BIRTH]
        ),
      },
    ];
  }

  const formIsValid = results.every((result) => result.valid === true);
  return {
    valid: formIsValid,
    fields: results,
  };
};

export {
  validTitle,
  validName,
  validDob,
  validNumber,
  validEmail,
  validateRequiredBoolean,
  validateRequired,
  validatePassword,
  validationAboutYou,
  REASONS,
};
