import { isEmpty, isNumber } from 'lodash';

import { PRODUCT_BROADBAND } from 'app/redux/modules/Bundles/constants';
import {
  PACKAGE_NAME_EERO,
  UI_PACKAGE_NAME_EERO,
} from 'redux/modules/Broadband/constants';

const LINE_ACTIVATION_FEATURE = 'Line activation';

const getRouterName = (broadband) => {
  const routerName = broadband.equipmentPackage.name;
  const isEero = routerName === PACKAGE_NAME_EERO;

  return isEero ? UI_PACKAGE_NAME_EERO : routerName;
};

const getHomePhoneServices = (homePhone) => {
  if (!homePhone) {
    return null;
  }

  const features = [];
  const featureName = homePhone.features.find(({ cost }) => cost)?.name;

  if (featureName) {
    features.push(featureName);
  }

  // there is no activation cost if customer has an existing landline
  if (homePhone.activationPrice) {
    features.push(LINE_ACTIVATION_FEATURE);
  }

  return isEmpty(features) ? null : features;
};

const getFeatures = ({ broadband, homePhone, breakdownHomePhone }) => {
  const features = [broadband.tariff.tariffLabel];
  const routerName = getRouterName(broadband);

  features.push(routerName);

  const homePhoneServices =
    breakdownHomePhone && getHomePhoneServices(homePhone);

  if (homePhoneServices) {
    features.push(...homePhoneServices);
  }

  return features;
};

const getMonthlyPrices = (broadband, homePhone) => {
  let monthlyPrice = broadband.totalMonthlyCost.value;
  let promoMonthlyPrice = null;

  if (homePhone) {
    monthlyPrice += homePhone.totalMonthlyCost.value;
  }

  if (broadband.promoMonthlyCost?.cost) {
    promoMonthlyPrice = broadband.promoMonthlyCost.cost.value;

    if (homePhone) {
      promoMonthlyPrice +=
        homePhone.promoMonthlyCost?.cost?.value ??
        homePhone.totalMonthlyCost.value;
    }
  }

  return {
    price: {
      ...broadband.totalMonthlyCost,
      value: monthlyPrice,
    },
    discountedPrice: isNumber(promoMonthlyPrice)
      ? {
          ...broadband.totalMonthlyCost,
          value: promoMonthlyPrice,
        }
      : null,
  };
};

const transformBroadband = (
  broadband,
  homePhone,
  breakdownBroadband,
  breakdownHomePhone
) => {
  if (!broadband) {
    return null;
  }

  const features = getFeatures({ broadband, homePhone, breakdownHomePhone });
  const { price, discountedPrice } = getMonthlyPrices(
    breakdownBroadband,
    breakdownHomePhone
  );

  return {
    id: PRODUCT_BROADBAND,
    discountedPrice,
    items: features,
    oneTimeCost: breakdownBroadband?.totalInitialCost || null,
    price,
    // Added for Try Before You Buy promo reason
    // Maybe we can use this in other places in the future
    serviceRaw: {
      ...broadband,
      price,
      discountedPrice,
    },
  };
};

export {
  transformBroadband,
  getMonthlyPrices,
  getHomePhoneServices,
  getFeatures,
  LINE_ACTIVATION_FEATURE,
};
