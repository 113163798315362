import { ReferralAndLegalFormSection } from 'app/components/modules/YourDetails/ReferralAndLegalFormSection';
import { ApplicationApi } from 'redux/modules/App/api';
import { OrderSummaryApi } from 'app/redux/modules/OrderSummary/api';
import { PartnerLoginApi } from 'redux/modules/PartnerLogin/api';
import { ServiceSelectionApi } from 'redux/modules/ServiceSelection/api';
import {
  getFormFields,
  getFormValidationErrors,
  getFieldPristine,
} from 'redux/modules/YourDetails/api';
import {
  REFERRAL_AND_LEGAL_SECTION,
  STORE_NAME,
} from 'redux/modules/YourDetails/constants';
import {
  setOptOutMarketing,
  updateReferralAndLegalForm,
  termsAndConditionsUpdate,
  eeccContractLinkClicked,
} from 'redux/modules/YourDetails/actions';
import { connector } from 'redux/utils/connector';
import * as api from 'redux/modules/YourDetails/api';

const mapStateToProps = (state) => {
  const isHosted = Boolean(PartnerLoginApi.getHostedId(state));
  const hasLead = OrderSummaryApi.getHasApplicationLead(state);
  const isOrganic = ApplicationApi.isOrganicApplication(state);
  const selectedServicesAmount =
    ServiceSelectionApi.getSelectedServices(state)?.length ?? 0;
  const displayEecc =
    ServiceSelectionApi.hasBroadband(state) ||
    ServiceSelectionApi.hasMobile(state);
  return {
    displayEecc,
    values: getFormFields(state, REFERRAL_AND_LEGAL_SECTION),
    errors: getFormValidationErrors(state, REFERRAL_AND_LEGAL_SECTION),
    pristine: getFieldPristine(state, REFERRAL_AND_LEGAL_SECTION),
    hasMultipleServices: selectedServicesAmount > 1,
    isReferralEnabled: !isHosted && !hasLead && isOrganic,
    oneTouchSwitchTermsAndConditionsState: api.getOTSTermsAndConditionsState(
      state,
      OrderSummaryApi.getHasBroadband(state)
    ),
  };
};

const ReferralAndLegalFormSectionContainer = connector(
  STORE_NAME,
  mapStateToProps,
  {
    onMarketingOptChange: setOptOutMarketing,
    onFormChange: updateReferralAndLegalForm,
    onEeccClick: eeccContractLinkClicked,
    onTermsChecked: termsAndConditionsUpdate,
  }
)(ReferralAndLegalFormSection);

export { ReferralAndLegalFormSectionContainer };
