/*
 * Letters permitted have been resolved by searching for characters that hold the Unicode "letter" property
 * which also reside within Latin flavour blocks. Note that Latin Extended-[CDE] are intentionally excluded, as
 * these are not well supported.
 *
 * Ranges & characters are described below.
 *
 * U+0041 (A) - U+005A (Z) = Basic Latin (Uppercase Alphabet)
 * U+0061 (a) - U+007A (z) = Basic Latin (Lowercase Alphabet)
 * U+00AA (ª)              = Latin-1 Supplement (Feminine Ordinal Indicator)
 * U+00B5 (µ)              = Latin-1 Supplement (Micro Sign)
 * U+00BA (º)              = Latin-1 Supplement (Masculine Ordinal Indicator)
 * U+00C0 (À) - U+00D6 (Ö) = Latin-1 Supplement (Letters)
 * U+00D8 (Ø) - U+00F6 (ö) = Latin-1 Supplement (Letters continued)
 * U+00F8 (ø) - U+024F (ɏ) = Latin-1 Supplement (Letters continued) + Latin Extended-A + Latin Extended-B
 * U+1E00 (Ḁ) - U+1EFE (Ỿ) = Latin Extended Additional
 */
// https://mathiasbynens.be/notes/javascript-escapes#unicode-code-point
export const regexLatinChars = /^['\u{02BC}\u{2019}\u{0041}-\u{005A}\u{0061}-\u{007A}\u{00AA}\u{00B5}\u{00BA}\u{00C0}-\u{00D6}\u{00D8}-\u{00F6}\u{00F8}-\u{024F}\u{1E00}-\u{1EFE}\s'-]+$/iu;
export const regexLatinCharsExtended = /^[0-9\u{02BC}\u{2019}\u{0041}-\u{005A}\u{0061}-\u{007A}\u{00AA}\u{00B5}\u{00BA}\u{00C0}-\u{00D6}\u{00D8}-\u{00F6}\u{00F8}-\u{024F}\u{1E00}-\u{1EFE}\s!?/<>.,'"@:()[\]&-]+$/iu;
export const ERROR_INVALID_NAME = 'Please provide a valid name';
export const ERROR_MISSING_NAME = 'Please provide a name';

export const isNameValid = (name, extended = false) => {
  if (!name || name.length < 2) {
    return { error: ERROR_MISSING_NAME, isValid: false };
  }
  const isAlphaName = extended
    ? regexLatinCharsExtended.test(name)
    : regexLatinChars.test(name);
  if (!isAlphaName) {
    return { error: ERROR_INVALID_NAME, isValid: false };
  }
  return { isValid: true };
};
