import { connector } from 'redux/utils/connector';
import AboutYouForm from 'app/components/modules/YourDetails/AboutYouSection';
import {
  STORE_NAME,
  ABOUT_YOU_SECTION,
} from 'redux/modules/YourDetails/constants';
import applicationCheck from 'redux/modules/ApplicationCheck';
import { ApplicationApi } from 'redux/modules/App/api';
import { ServiceSelectionApi } from 'redux/modules/ServiceSelection/api';
import CashbackApi from 'redux/modules/Cashback/api';
import * as api from 'redux/modules/YourDetails/api';
import {
  updateFormAboutYou,
  setPaperBillingModal,
  submitDetailsForm,
  requestEeccContract,
} from 'redux/modules/YourDetails/actions';

const mapStateToProps = (state) => ({
  fields: api.getFormFields(state, ABOUT_YOU_SECTION),
  valid: api.getFormValid(state, ABOUT_YOU_SECTION),
  errors: api.getFormValidationErrors(state, ABOUT_YOU_SECTION),
  fieldPristine: api.getFieldPristine(state, ABOUT_YOU_SECTION),
  pristine: api.getAnyFieldPristine(state, ABOUT_YOU_SECTION),
  paperBillingModal: api.getPaperBillingModal(state),
  hasCashback: CashbackApi.getHasApplied(state),
  eeccContractLoading: api.getEeccRequestLoading(state),
  isSales: ApplicationApi.isSalesApplication(state),
  isTalkTalk: ApplicationApi.isTalkTalkApplication(state),
  displayEecc:
    ServiceSelectionApi.hasBroadband(state) ||
    ServiceSelectionApi.hasMobile(state),
});

const YourDetailsAboutYou = connector(STORE_NAME, mapStateToProps, {
  updateForm: updateFormAboutYou,
  setPaperBillingModal,
  onContinuePaperBilling: submitDetailsForm,
  requestEeccContract,
})(AboutYouForm);

export default applicationCheck(YourDetailsAboutYou);
