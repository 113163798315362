import { oneTouchSwitchSection } from '../form';

const validCurrentProvider = (value, providerNotListed) => {
  if (!value && !providerNotListed) {
    return {
      valid: false,
      reason: 'Enter a Current Provider',
    };
  }
  return { valid: true };
};

const validFindStatus = (value) => {
  if (value === 'NOT_STARTED') {
    return {
      valid: false,
      reason: 'Necessary completion of ots not done',
    };
  }
  return { valid: true };
};

const isTruthy = (value, name) => {
  if (!value) {
    return {
      valid: false,
      reason: `Invalid ${name}`,
    };
  }
  return { valid: true };
};

const validateOneTouchSwitch = (fields) => {
  let results = [];
  if (
    fields[oneTouchSwitchSection.HAS_EXISTING_LINE] &&
    !fields[oneTouchSwitchSection.PROVIDER_NOT_LISTED]
  ) {
    results = [
      {
        field: oneTouchSwitchSection.CURRENT_PROVIDER,
        ...validCurrentProvider(
          fields[oneTouchSwitchSection.CURRENT_PROVIDER],
          fields[oneTouchSwitchSection.PROVIDER_NOT_LISTED]
        ),
      },
      {
        field: oneTouchSwitchSection.FIND_STATUS,
        ...validFindStatus(fields[oneTouchSwitchSection.FIND_STATUS]),
      },
      {
        field: oneTouchSwitchSection.MATCH_ID,
        ...isTruthy(fields[oneTouchSwitchSection.MATCH_ID], 'match ID'),
      },
    ];
  } else {
    results = [
      {
        valid: true,
        fields: [],
      },
    ];
  }
  const formIsValid = results.every((result) => result.valid === true);
  return { valid: formIsValid, fields: results };
};

export { validCurrentProvider, validateOneTouchSwitch };
