import { authenticatedPost } from 'redux/utils/request';

export const postOneTouchSwitch = ({
  providerNotListed,
  provider,
  correlationId,
  switchingReference,
  losingProviderAccountNumber,
}) =>
  authenticatedPost('products/broadband/switch', {
    providerNotListed,
    provider,
    correlationId,
    switchingReference,
    losingProviderAccountNumber,
  });
